import React from "react"
import Layout from "../layouts"
import Card from "../components/Card"

import people from "../content/people.json"

export default () => (
  <Layout>
    <div className="mw-beskar mt6 center ph3 ph4-l flex flex-wrap items-center-l justify-between h-100">
      {people.team.map(profile => (
        <div className="pa1">
          <Card
            name={profile.member.name}
            photoUrl={profile.member.avatar}
            linkedinUrl={profile.member.linkedin}
            description={profile.member.role}
          />
        </div>
      ))}
    </div>
  </Layout>
)
