import React from "react"
import LinkedinIcon from "../../icons/linkedin.svg"
import { Link } from "gatsby"

const Card = ({ name, description, photoUrl, linkedinUrl }) => (
  <article className="mw6 center bg-moon-gray br3 pa3 pa3-ns mv3 ba b--black-10">
    <div className="tc">
      <img
        src={photoUrl}
        className="db br3"
        title="Profile image"
        height="250"
        width="250"
      />
      <hr className="mw3 bb bw1 b--black-10" />
      <div className="flex items-start justify-between">
        <div className="flex flex-column items-start justify-between">
          <div className="f3">{name}</div>
          <div className="lh-copy measure f6 black-70">{description}</div>
        </div>
        <div className="flex items-center justify-center">
          <Link className="db" to={linkedinUrl}>
            <img
              className="w2"
              src={LinkedinIcon}
              alt="link to linkedin profile"
            />
          </Link>
        </div>
      </div>
    </div>
  </article>
)

export default Card
